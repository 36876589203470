import { PageProps, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Helmet from "react-helmet"
import NewLoginForm from "../../components/ContulMeu/NewLoginForm"
import NewSignupForm from "../../components/ContulMeu/NewSignUpForm"
import Footer from "../../components/Footer"
import { InfoMsgType } from "../../components/InfoMsg"
import ResetPassModal from "../../components/ResetPassModal"
import SiteMenu from "../../components/siteMenu"
import { getAllUrlParams } from "../../utils"
import { LoginFormData, postLogin, postSignUp } from "../../utils/api/login"
import {
  saveContracts,
  saveToken,
  saveUserInfo,
} from "../../utils/services/keepAuth"
import "./styles.css"
import NewResetPassModal from "../../components/Popups/NewResetPassModal"
import userBlurredBackground from "../../components/Hooks/useBlurredBackground"
import { useStopBodyScrollingWithCondition } from "../../components/Hooks/useStopBodyScrollingWithCondition"

export default function ContulMeuPage({ location }: PageProps) {
  const [showForgotPasswordModal, setShowForgotPasswordModal] =
    React.useState(false)
  const [invitationToken, setInvitationToken] = React.useState<string | null>(
    null
  )
  const [loginInfoMsg, setLoginInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })
  const [signUpInfoMsg, setSignUpInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })

  userBlurredBackground({
    querySelector: ".firstRow",
    showPopup: showForgotPasswordModal,
  })

  useStopBodyScrollingWithCondition({
    shouldStopScrolling: showForgotPasswordModal,
  })

  React.useEffect(() => {
    if (location != null) {
      const params = getAllUrlParams(location.search)
      if (params != null && params.invitationToken != null) {
        navigate("/contulMeu#newSignUpForm")
        setInvitationToken(params.invitationToken)
      }
    }

    if (location.search && location.search.includes("showResetPassword"))
      setShowForgotPasswordModal(true)
  }, [location])

  const displayForgotPasswordModal = () => {
    setShowForgotPasswordModal(true)
  }

  const hideForgotPasswordModal = () => {
    setShowForgotPasswordModal(false)
  }

  const tryUserLogin = async (formData: LoginFormData) => {
    setLoginInfoMsg({
      isLoading: true,
    })
    try {
      const { type, data, reason } = await postLogin(formData)
      if (type) {
        // console.log(data)
        saveToken(data.token, formData.keepAuth)
        saveUserInfo({ _id: data._id, ...data.userInfo })
        saveContracts(data.contracts)
        navigate("/app/home/")
        // setLoginInfoMsg(
        //   "A-ți fost autentificat cu succes! În câteva momente veți fi redirecționat către contul tău."
        // )
      } else
        switch (reason) {
          case 0:
            setLoginInfoMsg({
              isLoading: false,
              status: "error",
              msg: "Nu a fost găsit nici un cont pentru adresa de email introdusă",
            })
            break
          case 1:
            setLoginInfoMsg({
              isLoading: false,
              status: "error",
              msg: "Parola introdusă este greșită",
            })
            break
          case 2:
            setLoginInfoMsg({
              isLoading: false,
              status: "error",
              msg: "Contul dumneavoastră a fost blocat temporar din cauză ca ați introdus parola greșit de mai multe ori. Reîncercați în două ore sau resetați parola.",
            })
            break
          case 3:
            setLoginInfoMsg({
              isLoading: false,
              status: "error",
              msg: "Contul dumneavoastră a fost blocat. Pentru al debloca trebuie să vă resetați parola",
            })

            break
          default:
            break
        }
    } catch (error) {
      console.error(error)
      setLoginInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare la incercarea de conectare.",
      })
    }
  }

  const tryUserSignUp = async formData => {
    setSignUpInfoMsg({
      isLoading: true,
    })
    setTimeout(async () => {
      try {
        const {
          type,
          data,
          existingAccountForEmail,
          existingAccountForCodClient,
          accountRegistered,
          clientNotFound,
          obscuredEmailAddress,
        } = await postSignUp({ ...formData, invitationId: invitationToken })
        if (type)
          if (existingAccountForEmail)
            setSignUpInfoMsg({
              isLoading: false,
              status: "error",
              msg: "Acest email este deja folosit, dați click pe Am uitat parola?. Pentru clienti care au 2 locații de consum, din păcate nu se poate folosi același email pentru a doua locație.",
            })
          else if (existingAccountForCodClient)
            setSignUpInfoMsg({
              isLoading: false,
              status: "error",
              msg: `Acest cod client și număr de contract sunt deja folosite de către ${
                obscuredEmailAddress
                  ? "emailul " + obscuredEmailAddress
                  : "alt email"
              }, dacă ați pierdut accesul la respectivul email completați un formular de contact.`,
            })
          else if (clientNotFound)
            setSignUpInfoMsg({
              isLoading: false,
              status: "error",
              msg: "Codul de client și numărul de contract nu se regăsesc în baza noastră de date. Verificați dacă le-ați introdus corect. Pentru ajutor intrati in sectiune Contact si completați un formular.",
            })
          else if (accountRegistered)
            setSignUpInfoMsg({
              isLoading: false,
              status: "ok",
              msg: "Emailul pentru activare contului a fost transmis.",
            })
          else if (data != null && data.token != null) {
            setSignUpInfoMsg({
              isLoading: false,
              status: "ok",
              msg: "Contul a fost creat cu succes. În cateva momente vei fi redirecționat către contul tău.",
            })
            saveToken(data.token, true)
            saveUserInfo({ _id: data._id, ...data.userInfo })
            saveContracts(data.contracts)
            setTimeout(() => {
              navigate("/app/home/")
            }, 1000)
          }

        // console.log(type, data)
      } catch (err) {
        console.error(err)
        setSignUpInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare la crearea contului dumneavoastră.",
        })
      }
    }, 1000)
  }

  // const { invitationToken } = getAllUrlParams(location.search)

  return [
    <div key={1} className="contulMeuPage">
      {/* @ts-ignore */}
      <Helmet>
        <title>Gazmir.ro - Furnizor gaze naturale</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta
          name="description"
          content="Gazmir - furnizorul tau de gaze naturale. Beneficiaza de sprijin la
              plata facturilor, semneaza contractul de furnizare! Achita facturi
              online, vezi consumul"
        />
        <meta
          name="keywords"
          content="Gazmir,Furnizor gaz,Furnizare gaz,Furnizare gaze naturale,gaze
              naturale,gaz,gn,gaz miroslava,gaz panciu,gaz odobesti,gaz
              chirnogi,Gazmir miroslava,Gazmir panciu,Gazmir odobesti,Gazmir
              chirnogi,Gazmir.ro,Contract gaz,Pret gaz,Gaz Vrancea,Gaz Iasi"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />
      <div className="firstRow">
        <div id="loginSection" className="loginColumn">
          <div className="text">
            <h1>Conectează-te aici!</h1>
            {/* <p>
               Conectează-te pentru a putea vedea consumul, pentru a face plăți,
              mai mult descriptor 
            </p>*/}
          </div>
          <div className="form">
            <NewLoginForm
              infoMsg={loginInfoMsg}
              tryUserLogin={tryUserLogin}
              displayForgotPasswordModal={displayForgotPasswordModal}
            />
          </div>
        </div>
        <div className="avantajeColumn">
          <div className="text">
            <h1 className="colorOrangeDinu hideOnMobile">
              Avantajele contului
            </h1>
            <h1 className="colorOrangeDinu visibleOnTabletWithFlex">
              Creează cont
            </h1>
            <div className="avantajeList">
              <div className="column">
                <p className="avantaj">Plăți online</p>
                <p className="avantaj">Sold la zi</p>
                <p className="avantaj">Istoric plăți</p>
              </div>
              <div className="column">
                <p className="avantaj">Istoric consum</p>
                <p className="avantaj">Autocitire online</p>
                <p className="avantaj">Factură electronică</p>
              </div>
            </div>
          </div>
          <StaticImage
            height={608}
            className="image"
            src="../../images/contulMeuMid.jpeg"
            alt="Gazmir.ro - platformă clienți "
          />
        </div>
        <div className="signUpColumn">
          <div className="text">
            <h1 className="colorOrangeDinu hideOnMobile">Creează cont</h1>
            {/* <p>
             Înregistrează-te pentru a putea vedea consumul, pentru a face
              plăți, mai mult descriptor 
            </p>*/}
          </div>
          <div className="form">
            <NewSignupForm
              invitationId={invitationToken}
              infoMsg={signUpInfoMsg}
              tryUserSignUp={tryUserSignUp}
            />
          </div>
        </div>
      </div>
      {showForgotPasswordModal && (
        // <ResetPassModal
        //   key={4}
        //   hideModal={hideForgotPasswordModal}
        //   showLoginModal={hideForgotPasswordModal}
        // />
        <NewResetPassModal key={4} hideModal={hideForgotPasswordModal} />
      )}
      {/* <CookieBanner /> */}
    </div>,

    <Footer key={2} containerClassName="homeFooter" />,
  ]
}
