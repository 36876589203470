import { LocalContract, LocalUserInfo } from "../services/keepAuth"
import { apiBase, ApiResponse, checkStatus } from "./utils"

const baseUrl = `${apiBase}`

export type LoginFormData = {
  email: string
  password: string
  keepAuth: boolean
}

type LoginResponseData = {
  _id: string
  token: string
  contracts: LocalContract[]
  userInfo: Omit<LocalUserInfo, "_id">
}

interface LoginResponse extends ApiResponse<LoginResponseData> {
  reason: number
}

export function postLogin(data: LoginFormData): Promise<LoginResponse> {
  const requestOptions = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/login`, requestOptions).then(
    checkStatus
  ) as Promise<LoginResponse>
}

type SignUpFormData = {}

interface SignUpResponse extends ApiResponse<LoginResponseData | null> {
  existingAccountForEmail: boolean
  existingAccountForCodClient: boolean
  accountRegistered: boolean
  clientNotFound: boolean
  obscuredEmailAddress: string
}
export function postSignUp(data): Promise<SignUpResponse> {
  const requestOptions = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/signUp`, requestOptions).then(
    checkStatus
  ) as Promise<SignUpResponse>
}

type putActivateClientAccountData = {
  accountId: string
  token: string
}

export function putActivateClientAccount(
  data: putActivateClientAccountData
): Promise<LoginResponse> {
  const requestOptions = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/activateClientAccount`, requestOptions).then(
    checkStatus
  ) as Promise<LoginResponse>
}

type PutUndoBody = {
  emailSwapId: string
  token: string
}

export function putUndoEmailSwap(
  data: PutUndoBody
): Promise<ApiResponse<null>> {
  const requestOptions = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/undoEmailSwap`, requestOptions).then(
    checkStatus
  ) as Promise<ApiResponse<null>>
}

type PutValidateEmailResetPassBody = {
  email: string
}

export function putValidateEmailResetPass(
  data: PutValidateEmailResetPassBody
): Promise<ApiResponse<null>> {
  const requestOptions = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/validateEmailResetPass`, requestOptions).then(
    checkStatus
  ) as Promise<ApiResponse<null>>
}

type PutValidateResetPassCodeBody = {
  email: string
  resetPassCode: string
}

export function putValidateResetPassCode(
  data: PutValidateResetPassCodeBody
): Promise<ApiResponse<null>> {
  const requestOptions = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/validateResetPassCode`, requestOptions).then(
    checkStatus
  ) as Promise<ApiResponse<null>>
}

type PutResetPassBody = {
  email: string
  resetPassCode: string
  firstPass: string
  secondPass: string
}

interface PutResetPassResponse extends ApiResponse<null> {
  passwordMustBeDifferent: boolean
}

export function putResetPass(
  data: PutResetPassBody
): Promise<PutResetPassResponse> {
  const requestOptions = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/resetPass`, requestOptions).then(
    checkStatus
  ) as Promise<PutResetPassResponse>
}

type GetLinkInviteDataResponseData = {
  codClient: string
  nrContract: string
  email: string
}

export async function getLinkInviteData(
  invitationId: string
): Promise<ApiResponse<GetLinkInviteDataResponseData>> {
  const requestOptions = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }
  return fetch(
    `${baseUrl}/api/linkInvite/${invitationId}`,
    requestOptions
  ).then(checkStatus) as Promise<ApiResponse<GetLinkInviteDataResponseData>>
}
