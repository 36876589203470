import React, { FormEvent } from "react"
import {
  putResetPass,
  putValidateEmailResetPass,
  putValidateResetPassCode,
} from "../../utils/api/login"
import {
  CONFIRM_PASSWORD_ERROR,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PASSWORD_ERROR,
  PASSWORD_REGEX,
  VALIDATION_CODE_ERROR,
  VALIDATION_CODE_REGEX,
} from "../../utils/regex"
import Button from "../Button"
import svgs from "../Dash/svgs"
import FormInput from "../FormInput"
import InfoMsg, { InfoMsgType } from "../InfoMsg"

type PropTypes = {
  hideModal: () => void
}

type FormData = {
  step: number
  email: string
  resetPassCode: string
  secondPass: string
  firstPass: string
  keepAuth: boolean
}

function getHintMsgForStep(step: number) {
  switch (step) {
    case 1:
      return "Introdu adresa de email pentru care dorești să resetezi parola."
    case 2:
      return "Introdu codul primit pe email."
    case 3:
      return "Introdu noua parolă de două ori și confirmă schimbarea."
    default:
      return ""
  }
}

function checkFormData(formData: FormData) {
  switch (formData.step) {
    case 1:
      return EMAIL_REGEX(formData.email)
    case 2:
      return VALIDATION_CODE_REGEX(formData.resetPassCode)
    case 3:
      return (
        formData.firstPass === formData.secondPass &&
        PASSWORD_REGEX(formData.firstPass)
      )
    default:
      return false
  }
}

export default function NewResetPassModal({ hideModal }: PropTypes) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })
  const [formData, setFormData] = React.useState<FormData>({
    step: 1,
    email: "",
    firstPass: "",
    keepAuth: false,
    resetPassCode: "",
    secondPass: "",
  })

  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))

    if (infoMsg.status !== "ok")
      setInfoMsg({
        isLoading: false,
      })
  }

  const testConfirmPassword = parolaConfirm =>
    formData.firstPass === parolaConfirm

  const sendEmailCode = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await putValidateEmailResetPass({
        email: formData.email,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
        })
        setFormData(oldState => ({ ...oldState, step: 2 }))
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Nu există nici un cont cu această adresă de email",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  const validatePassCode = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await putValidateResetPassCode({
        email: formData.email,
        resetPassCode: formData.resetPassCode,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
        })
        setFormData(oldState => ({ ...oldState, step: 3 }))
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Codul de validare este greșit.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  const changePass = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await putResetPass({
        email: formData.email,
        resetPassCode: formData.resetPassCode,
        firstPass: formData.firstPass,
        secondPass: formData.secondPass,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
          status: "ok",
          msg: "Parola dvs. a fost schimbată. În căteva momente veți fi redirecționat către login.",
        })
        setTimeout(() => {
          hideModal()
        }, 1000)
      } else if (apiResponse.passwordMustBeDifferent)
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Parola nouă trebuie să fie diferită de cea veche.",
        })
      // setStep(3)
      else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Nu am putu finaliza schibarea parolei. Vă rugăm reîncercați de la primul pas.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    console.log("Step: ", formData.step)
    switch (formData.step) {
      case 1:
        sendEmailCode()
        break
      case 2:
        validatePassCode()
        break
      case 3:
        changePass()
        break
      default:
        break
    }
    // setStep(oldStep => oldStep + 1)
    // tryUserLogin(formData)
  }

  return (
    <div className="popupLayout">
      <div className="popupContainer">
        <div
          className="popUpCloseIcon"
          role="button"
          tabIndex={0}
          // style={styles.loginLogoContainer}
          onClick={hideModal}
        >
          {svgs.closeIcon}
        </div>
        {/* <div className="modalLogoContainer hideOn600">
          <StaticImage
            height={60}
            aspectRatio={2.23}
            loading="eager"
            // layout="constrained"
            src="../images/logoNou.png"
            alt="Logo gazmir"
          />
        </div> */}
        <div className="text">
          <div className="popupTitle popupExtraTitle colorPrimary">
            Resetare parolă
          </div>
        </div>
        <div className="popupMsg textAlignLeft">
          {getHintMsgForStep(formData.step)}
        </div>
        <form id="resetPassForm" className="newStyleForm" onSubmit={submitForm}>
          {formData.step < 3 && (
            <FormInput
              autoComplete="email"
              containerClassName="newStyleInput"
              type="email"
              label="Email"
              name="email"
              onChange={handleInputChange}
              value={formData.email}
              errorTest={EMAIL_REGEX}
              errorMessage={EMAIL_ERROR}
            />
          )}
          {formData.step === 2 && (
            <FormInput
              autoComplete="one-time-code"
              containerClassName="newStyleInput"
              type="text"
              placeholder="Cod de verificare"
              name="resetPassCode"
              onChange={handleInputChange}
              value={formData.resetPassCode}
              errorTest={VALIDATION_CODE_REGEX}
              errorMessage={VALIDATION_CODE_ERROR}
            />
          )}
          {formData.step === 3 && [
            <FormInput
              key={0}
              autoComplete="new-password"
              containerClassName="newStyleInput"
              type="password"
              label="Introdu noua parolă"
              name="firstPass"
              onChange={handleInputChange}
              value={formData.firstPass}
              errorTest={PASSWORD_REGEX}
              errorMessage={PASSWORD_ERROR}
            />,

            <FormInput
              key={1}
              autoComplete="new-password"
              containerClassName="newStyleInput"
              type="password"
              label="Confirmă noua parolă"
              name="secondPass"
              onChange={handleInputChange}
              value={formData.secondPass}
              errorTest={testConfirmPassword}
              errorMessage={CONFIRM_PASSWORD_ERROR}
            />,
          ]}
          <InfoMsg containerClassName="row" infoMsg={infoMsg} />
          <div className="row rowButton">
            <Button
              isLoading={infoMsg.isLoading}
              as="button"
              type="submit"
              label={
                formData.step === 1
                  ? "Trimite cod"
                  : formData.step === 2
                  ? "Introdu codul"
                  : "Schimbă parola"
              }
              className="newStyleButton"
              aspect="primary"
              disabled={!checkFormData(formData)}
              // onClick={submitForm}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
