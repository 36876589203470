import React, { FormEvent } from "react"
import Button from "../../components/Button"
import FormInput from "../../components/FormInput"
import InfoMsg, { InfoMsgType } from "../../components/InfoMsg"
import { LoginFormData } from "../../utils/api/login"
import { EMAIL_ERROR, EMAIL_REGEX } from "../../utils/regex"

type PropTypes = {
  infoMsg: InfoMsgType
  tryUserLogin: (data: LoginFormData) => void
  displayForgotPasswordModal: () => void
}

export default function NewLoginForm({
  infoMsg,
  tryUserLogin,
  displayForgotPasswordModal,
}: PropTypes) {
  const [formData, setFormData] = React.useState<LoginFormData>({
    email: "",
    password: "",
    keepAuth: true,
  })

  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const toggleKeepAuth = () => {
    setFormData(oldFormData => ({
      ...oldFormData,
      keepAuth: !oldFormData.keepAuth,
    }))
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    console.log("Submitting login form: ", formData)
    tryUserLogin(formData)
  }

  return (
    <form id="newLoginForm" className="newStyleForm" onSubmit={submitForm}>
      <FormInput
        autoComplete="email"
        containerClassName="newStyleInput"
        type="email"
        label="Email"
        name="email"
        onChange={handleInputChange}
        value={formData.email}
        errorTest={EMAIL_REGEX}
        errorMessage={EMAIL_ERROR}
      />
      <FormInput
        autoComplete="current-password"
        containerClassName="newStyleInput"
        type="password"
        label="Parolă"
        name="password"
        onChange={handleInputChange}
        value={formData.password}
        // errorTest={EMAIL_REGEX}
        // errorMessage={EMAIL_ERROR}
      />
      <div className="row">
        <div className="roundCheckbox newStyleCheckBoxContainer">
          <input
            type="checkbox"
            id="keepAuth"
            onClick={toggleKeepAuth}
            checked={formData.keepAuth}
          />
          <label htmlFor="keepAuth"></label>
          <span>Vreau să rămân autentificat</span>
        </div>
        <div className="forgotPassword">
          <a onClick={displayForgotPasswordModal}>Ai uitat parola?</a>
        </div>
      </div>
      <InfoMsg containerClassName="row" infoMsg={infoMsg} />
      <div className="row rowButton">
        <Button
          isLoading={infoMsg.isLoading}
          as="button"
          type="submit"
          label="Intră în cont"
          className="newStyleButton"
          aspect="primary"
          disabled={
            !EMAIL_REGEX(formData.email) || formData.password.length < 1
          }
          // onClick={submitForm}
        />
      </div>
    </form>
  )
}
